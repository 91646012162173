/* eslint-disable @typescript-eslint/no-explicit-any */
"use client";

import React, { useEffect } from "react";
import { useSearchParams } from "next/navigation";
import { ChevronDown } from "lucide-react";

import { cn } from "@/lib/utils";
import {
  NavigationMenu,
  NavigationMenuContent,
  NavigationMenuItem,
  NavigationMenuList,
  NavigationMenuTrigger,
} from "@/components/ui/navigation-menu";
import ContentLayout from "../layouts/ContentLayout";
// import { esgLinks } from "@/mocks/navbarMocks";
import NavSearchPanel from "./NavSearchPanel";
import LocaleSwitcher from "./LocaleSwitcher";
import { Locale } from "@/i18n.config";
import CustomLink from "../other/CustomLink";
import { Popover, PopoverContent, PopoverTrigger } from "@/components/ui/popover";
import { navTriggerStyle } from "../other/constants";
import Breadcrumbs from "./Breadcrumbs";
import { useMediaQuery } from "@/hooks/mediaQuery";
import NavPanelWithLeftMenu from "./NavPanelWithLeftMenu";
import NavPanelNoMenu from "./NavPanelNoMenu";
import dynamic from "next/dynamic";

// because call rail script is changing phone number, we get hydration error...thus dynamic import
const TelNumberNavbar = dynamic(() => import("./TelNumberNavbar"), { ssr: false });

interface IMegaMenu {
  dict: { [key: string]: { [key: string]: string } };
  lang: Locale;
  isHidden: boolean;
  isVisible: boolean;
  pathname: string;
  contactNumber: string;
  navData: any;
  esgMenuItems: any;
}

interface IESGLinks {
  id: string;
  label: string;
  link?: string;
  children?: {
    id: string;
    label: string;
    link: string;
  }[];
}

export function MegaMenuLinks({
  dict,
  lang,
  isHidden,
  isVisible,
  pathname,
  navData,
  esgMenuItems,
  contactNumber,
}: IMegaMenu) {
  const [openESGReport, setOpenESGReport] = React.useState(false);
  const [value, setValue] = React.useState("");
  const isDesktop = useMediaQuery("(min-width: 1024px)");
  const searchParams = useSearchParams();

  const isESG = pathname.includes("/about-us/sustainability-impact");

  useEffect(() => {
    setValue("");
    setOpenESGReport(false);
  }, [pathname, isDesktop, searchParams]);

  return (
    <>
      <ContentLayout
        bgColorClass="bg-black-5"
        innerClass="py-[11px] sm:py-[12px]"
        className="lg:flex hidden relative border-b-[1px] border-black-20 z-20 w-full min-w-[100%] items-center"
      >
        <NavigationMenu value={value} onValueChange={setValue} className="items-center">
          <NavigationMenuList className="flex items-center justify-start gap-8">
            {navData.map((navItem: any) => {
              if (!navItem.tabs.length) {
                return (
                  <li key={navItem.menuLabel}>
                    <CustomLink
                      href="/locations"
                      className={cn(
                        navTriggerStyle,
                        "after:-bottom-[3px] pb-[2px] font-saira text-14 font-[400] tracking-[-0.1px] leading-[1.7]"
                      )}
                    >
                      {navItem.menuLabel}
                    </CustomLink>
                  </li>
                );
              } else {
                return (
                  <NavigationMenuItem key={navItem.menuLabel}>
                    <NavigationMenuTrigger
                      className="megaMenuLinks"
                      onPointerMove={(e) => e.preventDefault()}
                      onPointerLeave={(e) => e.preventDefault()}
                      onPointerEnter={(e) => e.preventDefault()}
                    >
                      {navItem.menuLabel}
                    </NavigationMenuTrigger>
                    <NavigationMenuContent
                      className="NavigationMenuContent"
                      onPointerMove={(e) => e.preventDefault()}
                      onPointerLeave={(e) => e.preventDefault()}
                    >
                      {navItem.tabs.length > 1 ? (
                        <NavPanelWithLeftMenu tabs={navItem.tabs} lang={lang} />
                      ) : (
                        <NavPanelNoMenu data={navItem.tabs[0]} />
                      )}
                    </NavigationMenuContent>
                  </NavigationMenuItem>
                );
              }
            })}
          </NavigationMenuList>

          {/** right elements */}
          <NavigationMenuList className="flex items-center space-x-4">
            {contactNumber && (
              <>
                <TelNumberNavbar contactNumber={contactNumber} />
                {/** divider */}
                <li className="w-[1px] bg-black-40 h-[22px] mx-4" />
              </>
            )}

            {/** search */}
            <NavigationMenuItem className="ml-auto">
              <NavigationMenuTrigger
                className="megaMenuLinks ml-auto"
                onPointerMove={(e) => e.preventDefault()}
                onPointerLeave={(e) => e.preventDefault()}
              >
                {dict.navigation.search}
              </NavigationMenuTrigger>
              <NavigationMenuContent
                className="NavigationMenuContent"
                onPointerMove={(e) => e.preventDefault()}
                onPointerLeave={(e) => e.preventDefault()}
              >
                <NavSearchPanel lang={lang} dict={dict.navigation} />
              </NavigationMenuContent>
            </NavigationMenuItem>

            {/** divider */}
            <li className="w-[1px] bg-black-40 h-[22px] mx-4" />

            {/** locale switcher */}
            <LocaleSwitcher lang={lang} dict={dict.navigation} />
          </NavigationMenuList>
        </NavigationMenu>
      </ContentLayout>

      {isESG && (
        <ContentLayout
          bgColorClass="bg-black-5"
          innerClass="py-[11px] sm:py-[12px]"
          className="lg:flex hidden relative border-b-[1px] border-black-20 z-10 w-full min-w-[100%] items-center"
        >
          <div className="flex justify-end gap-8">
            {esgMenuItems.map((link: IESGLinks) => {
              if (link.link) {
                return (
                  <CustomLink
                    href={link.link}
                    className={cn("megaMenuLinks", navTriggerStyle)}
                    key={`${link.id}-esgLink`}
                  >
                    {link.label}
                  </CustomLink>
                );
              } else if (link.children) {
                return (
                  <Popover key={`${link.id}-esgLink`} open={openESGReport} onOpenChange={setOpenESGReport}>
                    <div className="text-4 relative flex items-center h-8">
                      <PopoverTrigger
                        className={cn(
                          navTriggerStyle,
                          "py-1 gap-1 megaMenuLinks",
                          openESGReport && "after:scale-x-100"
                        )}
                      >
                        {link.label}

                        <ChevronDown size={16} className={cn("transition", openESGReport && "rotate-180")} />
                      </PopoverTrigger>
                    </div>
                    <PopoverContent className="w-full z-[300] rounded-[0px] shadow-2xl border-[1px] border-slate-10 mt-2 border-t-none">
                      <ul className="flex flex-col gap-3">
                        {link.children.map((link) => {
                          return (
                            <CustomLink
                              href={link.link}
                              className={cn("megaMenuLinks", navTriggerStyle)}
                              key={`${link.id}-esgLink`}
                            >
                              {link.label}
                            </CustomLink>
                          );
                        })}
                      </ul>
                    </PopoverContent>
                  </Popover>
                );
              }
            })}
          </div>
        </ContentLayout>
      )}

      {!isHidden && <Breadcrumbs lang={lang || "en"} isVisible={isVisible} dict={dict.breadcrumbs} />}

      {!!value && <div className="fixed bg-black opacity-80 top-[103px] left-0 right-0 bottom-0" />}
    </>
  );
}
