import { cn } from "@/lib/utils";

interface ISearchButtonProps {
  className?: string;
}

const SearchButton: React.FC<ISearchButtonProps> = ({ className }) => {
  return (
    <button aria-label="Search" className={cn("flex items-center justify-center", className)}>
      <svg
        aria-label="search icon"
        xmlns="http://www.w3.org/2000/svg"
        width="32"
        height="32"
        viewBox="0 0 32 32"
        fill="none"
      >
        <mask maskUnits="userSpaceOnUse" x="0" y="0" width="32" height="32">
          <rect width="32" height="32" fill="#D9D9D9" />
        </mask>
        <g mask="url(#mask0_83_21348)">
          <path
            d="M25.6423 26.8102L17.6953 18.8632C17.0309 19.438 16.2597 19.8831 15.3818 20.1986C14.5038 20.514 13.5952 20.6718 12.656 20.6718C10.3871 20.6718 8.46695 19.8864 6.89542 18.3157C5.32386 16.745 4.53809 14.8357 4.53809 12.5876C4.53809 10.3394 5.32343 8.42936 6.89412 6.85729C8.46479 5.2852 10.3758 4.49916 12.6272 4.49916C14.8785 4.49916 16.7901 5.28471 18.3617 6.85579C19.9334 8.42688 20.7192 10.3369 20.7192 12.5857C20.7192 13.5257 20.5594 14.435 20.2397 15.3137C19.9201 16.1923 19.4696 16.9778 18.8885 17.6701L26.891 25.6368C27.0517 25.7916 27.1321 25.9815 27.1321 26.2067C27.1321 26.4318 27.0403 26.6333 26.8568 26.8111C26.6882 26.9797 26.4843 27.0641 26.2451 27.0641C26.0059 27.0641 25.805 26.9795 25.6423 26.8102ZM12.6423 18.9966C14.4291 18.9966 15.9428 18.3748 17.1833 17.1312C18.4238 15.8876 19.0441 14.3724 19.0441 12.5855C19.0441 10.7986 18.4238 9.28333 17.1833 8.03973C15.9428 6.79613 14.4291 6.17433 12.6423 6.17433C10.8479 6.17433 9.32781 6.79613 8.08199 8.03973C6.83616 9.28333 6.21325 10.7986 6.21325 12.5855C6.21325 14.3724 6.83616 15.8876 8.08199 17.1312C9.32781 18.3748 10.8479 18.9966 12.6423 18.9966Z"
            fill="#051C2C"
          />
        </g>
      </svg>
    </button>
  );
};

export default SearchButton;
