import { useEffect, useState } from "react";

export const useScroll = () => {
  const [lastScrollTop, setLastScrollTop] = useState(0);
  const [bodyOffset, setBodyOffset] = useState(
    typeof window !== "undefined" ? document.documentElement.getBoundingClientRect() : { top: 0, left: 0 }
  );
  const [scrollY, setScrollY] = useState<number>(bodyOffset.top);

  const [scrollDirection, setScrollDirection] = useState<"down" | "up">();

  const listener = () => {
    typeof window !== "undefined" && setBodyOffset(document.documentElement.getBoundingClientRect());
    setScrollY(-bodyOffset.top);
    setScrollDirection(lastScrollTop > -bodyOffset.top ? "down" : "up");
    setLastScrollTop(-bodyOffset.top);
  };

  useEffect(() => {
    window.addEventListener("scroll", listener);
    return () => {
      window.removeEventListener("scroll", listener);
    };
  });

  return { scrollY, scrollDirection, isTop: bodyOffset.top === 0 };
};
