"use client";

import { usePathname } from "next/navigation";

import { Facebook, LinkedIn, WSLogoSmall, Instagram } from "@/assets/Icons";
import { SquareArrowOutUpRight } from "lucide-react";
import AppText from "./AppText";
import { AppButton } from "../buttons/AppButton";
import { AppIconButton } from "../buttons/AppIconButton";
import ContentLayout from "../layouts/ContentLayout";
import CustomLink from "./CustomLink";
import { BODY_SMALL_EMPHASIS } from "@/styles";
import { cn } from "@/lib/utils";

interface IItem {
  [key: string]: string;
}

interface IFooterProps {
  allRightsReserved: string;
  data: {
    footerText: string;
    block_1: {
      leftLabel: string;
      rightLabel: string;
      left: IItem[] | [];
      right: IItem[] | [];
    };
    block_2: {
      leftLabel: string;
      rightLabel: string;
      left: IItem[] | [];
      right: IItem[] | [];
    };
    whiteFooterLinks: IItem[] | [];
    socialsLinkedIn: string;
    socialsFacebook: string;
    socialsInstagram: string;
    socialsX: string;
    customScripts?: string[];
  };
  dict: { [key: string]: string };
}

const Footer: React.FC<IFooterProps> = ({ data, allRightsReserved, dict }) => {
  const pathname = usePathname();
  const isPreview = pathname.startsWith("/en/preview/blocks") || pathname.startsWith("/fr/preview/blocks");

  if (isPreview) {
    return null;
  }

  return (
    <footer>
      <ContentLayout bgColorClass="bg-navy-blue overflow-x-auto">
        <div className="lg:flex-row flex flex-col justify-between gap-6 overflow-auto min-w-[320px] pb-1 px-1">
          <div className="flex flex-col">
            {data.block_1.leftLabel && (
              <AppText type={"LABEL_LARGE"} as="h3" className="text-mellow mb-6">
                {data.block_1.leftLabel}
              </AppText>
            )}

            <div className="lg:w-[344px] hidden xs:flex justify-between gap-0 sm:gap-6">
              <div className=" flex flex-col flex-1">
                {data.block_1.left.map((link) => (
                  <AppButton
                    key={link.id}
                    link={link.link}
                    label={link.label}
                    intent="ghost"
                    bg="dark"
                    className={cn(BODY_SMALL_EMPHASIS, "text-white")}
                  />
                ))}
              </div>

              <div className="flex flex-col flex-1">
                {data.block_1.right.map((link) => {
                  const isOuterLink =
                    link.link === "https://www.willscotmobilemini.com/" ||
                    link.link === "https://careers.willscot-mobilemini.com/";
                  return isOuterLink ? (
                    <AppIconButton
                      icon={<SquareArrowOutUpRight size={14} color="#ffffff" arial-label="open outside" />}
                      iconOnRightSide
                      key={link.id}
                      link={link.link}
                      label={link.label}
                      intent="ghost"
                      bg="dark"
                      className={cn("gap-2", BODY_SMALL_EMPHASIS, "text-white")}
                    />
                  ) : (
                    <AppButton
                      key={link.id}
                      intent="ghost"
                      label={link.label}
                      bg="dark"
                      link={link.link}
                      className={cn(BODY_SMALL_EMPHASIS, "text-white")}
                    />
                  );
                })}
              </div>
            </div>

            <div className="lg:w-[344px] xs:hidden flex">
              <div className="grid w-full grid-cols-2">
                {data.block_1.left.map((link) => (
                  <AppButton
                    key={link.id}
                    link={link.link}
                    label={link.label}
                    intent="ghost"
                    bg="dark"
                    className={cn(BODY_SMALL_EMPHASIS, "text-white")}
                  />
                ))}

                {data.block_1.right.map((link) =>
                  link.showIcon ? (
                    <AppIconButton
                      icon={<SquareArrowOutUpRight size={14} color="#ffffff" arial-label="open outside" />}
                      iconOnRightSide
                      key={link.id}
                      link={link.link}
                      label={link.label}
                      intent="ghost"
                      bg="dark"
                      className={cn("gap-1", BODY_SMALL_EMPHASIS, "text-white")}
                    />
                  ) : (
                    <AppButton
                      key={link.id}
                      intent="ghost"
                      label={link.label}
                      bg="dark"
                      link={link.link}
                      className={cn(BODY_SMALL_EMPHASIS, "text-white")}
                    />
                  )
                )}
              </div>
            </div>
          </div>
          <div className="lg:flex flex-col hidden">
            {data.block_2.leftLabel && (
              <AppText type={"LABEL_LARGE"} as="h3" className="text-mellow mb-6">
                {data.block_2.leftLabel}
              </AppText>
            )}
            {data.block_2.left.map((link) => (
              <AppButton
                key={link.id}
                link={link.link}
                label={link.label}
                intent="ghost"
                bg="dark"
                className={cn(BODY_SMALL_EMPHASIS, "text-white")}
              />
            ))}
          </div>
          <div className="lg:flex flex-col hidden">
            {data.block_2.rightLabel && (
              <AppText type={"LABEL_LARGE"} as="h3" className="text-mellow mb-6">
                {data.block_2.rightLabel}
              </AppText>
            )}
            {data.block_2.right
              .map((link) => {
                if (!link.link && !link.label) return null;

                return (
                  <AppButton
                    key={link.id}
                    link={link.link}
                    label={link.label}
                    intent="ghost"
                    bg="dark"
                    className={cn(BODY_SMALL_EMPHASIS, "text-white")}
                  />
                );
              })
              .filter((item) => item)}
          </div>
          <div className="lg:hidden sm:gap-6 flex w-full gap-0">
            <div className="flex flex-col flex-1">
              {data.block_2.leftLabel && (
                <AppText type={"LABEL_LARGE"} as="h3" className="text-mellow mb-6">
                  {data.block_2.leftLabel}
                </AppText>
              )}
              {data.block_2.left.map((link) => (
                <AppButton
                  key={link.id}
                  link={link.link}
                  label={link.label}
                  intent="ghost"
                  bg="dark"
                  className={cn(BODY_SMALL_EMPHASIS, "text-white")}
                />
              ))}
            </div>

            <div className="flex flex-col flex-1">
              <AppText type={"LABEL_LARGE"} as="h3" className="text-mellow mb-6">
                {data.block_2.rightLabel}
              </AppText>
              {data.block_2.right
                .map((link) => {
                  if (!link.link && !link.label) return null;

                  return (
                    <AppButton
                      key={link.id}
                      link={link.link}
                      label={link.label}
                      intent="ghost"
                      bg="dark"
                      className={cn(BODY_SMALL_EMPHASIS, "text-white")}
                    />
                  );
                })
                .filter((item) => item)}
            </div>
          </div>

          <div className="flex gap-4 p-1">
            <a
              aria-label="LinkedIn link"
              target="_blank"
              href={data.socialsLinkedIn}
              className="flex items-center justify-center w-6 h-6 bg-white rounded cursor-pointer"
            >
              <LinkedIn />
            </a>
            <a
              aria-label="Facebook link"
              target="_blank"
              href={data.socialsFacebook}
              className="flex items-center justify-center w-6 h-6 bg-white rounded cursor-pointer"
            >
              <Facebook />
            </a>
            <a
              aria-label="Instagram link"
              target="_blank"
              href={data.socialsInstagram}
              className="flex items-center justify-center w-6 h-6 bg-white rounded cursor-pointer"
            >
              <Instagram />
            </a>
          </div>
        </div>
      </ContentLayout>

      <ContentLayout>
        <div className="lg:flex-row flex flex-col justify-between gap-6 mb-8">
          <div className="sm:flex-row sm:items-center flex flex-col items-start gap-6">
            <CustomLink href="/" ariaLabel={dict.buttonLogo}>
              <WSLogoSmall />
            </CustomLink>

            <AppText type="BODY_SMALL">{allRightsReserved}</AppText>
          </div>
          <div className="gap-x-8 gap-y-2 flex flex-wrap">
            {data.whiteFooterLinks.map((link: { [key: string]: string }) => (
              <AppButton
                key={link.id}
                link={link.link}
                label={link.label}
                intent="ghost"
                className={cn(BODY_SMALL_EMPHASIS)}
              />
            ))}
          </div>
        </div>
        <AppText type="BODY_SMALL">{data.footerText}</AppText>
      </ContentLayout>
    </footer>
  );
};

export default Footer;
