/* eslint-disable @typescript-eslint/no-explicit-any */
"use client";

import React, { useEffect, useRef, useState } from "react";
import { usePathname } from "next/navigation";

import { Locale } from "@/i18n.config";
import { useMediaQuery } from "@/hooks/mediaQuery";
import TopBar from "./TopBar";
import { MegaMenuLinks } from "./MegaMenuLinks";
import { useScroll } from "@/hooks/useScroll";
import dynamic from "next/dynamic";

const ESGMobileMenu = dynamic(() => import("./ESGMobileMenu"), { ssr: false });
const MobileMenu = dynamic(() => import("./MobileMenu"), { ssr: false });
const SearchMobileMenu = dynamic(() => import("./SearchMobileMenu"), { ssr: false });

interface INavbarProps {
  lang: Locale;
  dict: { [key: string]: { [key: string]: string } };
  navData: any;
  esgMenuItems: any;
  contactNumber: string;
  ctaTopBarButtons: any;
  portalLoginCtaLink: string;
}

interface KeyboardEvent {
  key: string;
}

const Navbar: React.FC<INavbarProps> = ({
  lang,
  dict,
  navData,
  esgMenuItems,
  contactNumber,
  ctaTopBarButtons,
  portalLoginCtaLink,
}) => {
  const [panelOpen, setPanelOpen] = useState<string | null>();
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [searchMenuOpen, setSearchMenuOpen] = useState(false);
  const [isVisible, setIsVisible] = useState(true);
  const pathname = usePathname();
  const isLargeScreen = useMediaQuery("(min-width: 1024px)");

  const { scrollDirection, isTop } = useScroll();

  const panelRef = useRef<HTMLDivElement>(null);
  const isHidden = mobileMenuOpen || searchMenuOpen;
  const isPreview = pathname.startsWith("/en/preview/blocks") || pathname.startsWith("/fr/preview/blocks");

  const isESG = pathname.includes("/about-us/sustainability-impact");

  useEffect(() => {
    if (isHidden) {
      const button = document.querySelector("#embeddedMessagingConversationButton") as HTMLButtonElement;
      if (button) {
        button.style.display = "none";
      }
    } else {
      const button = document.querySelector("#embeddedMessagingConversationButton") as HTMLButtonElement;
      if (button) {
        button.style.display = "block";
      }
    }
  }, [isHidden]);

  useEffect(() => {
    setMobileMenuOpen(false);
  }, [pathname]);

  useEffect(() => {
    if (!scrollDirection || isTop) {
      setIsVisible(true);
      return;
    }

    setIsVisible(scrollDirection === "down");
  }, [scrollDirection, isTop, pathname]);

  useEffect(() => {
    if (typeof window === "undefined") return;

    window.addEventListener("keydown", handleEscape);

    return () => window.removeEventListener("keydown", handleEscape);
  }, []);

  useEffect(() => {
    if (typeof window === "undefined") return;
    setMobileMenuOpen(false);
    setPanelOpen(null);
    setSearchMenuOpen(false);
  }, [isLargeScreen]);

  useEffect(() => {
    if (panelRef.current) {
      panelRef.current.focus();
    }
  }, [panelOpen]);

  useEffect(() => {
    if (typeof window === "undefined") return;

    document.body.style.overflow = isHidden ? "hidden" : "unset";
  }, [isHidden]);

  const handleEscape = (e: KeyboardEvent) => {
    if (e.key === "Escape") {
      setPanelOpen(null);
      setMobileMenuOpen(false);
    }
  };

  const handleMobileMenu = () => {
    setMobileMenuOpen((prev) => !prev);
    setSearchMenuOpen(false);
  };

  const handleSearchMenu = () => {
    setMobileMenuOpen(false);
    setSearchMenuOpen((prev) => !prev);
  };

  if (isPreview) {
    const main = document.querySelector("main");
    main && (main.style.marginTop = "0px");
    return null;
  }

  return (
    <div
      id="navHeader"
      className="fixed top-0 left-0 right-0 z-[260] bg-white transition-all overflow-y-visible h-[65px] lg:h-[144px]"
    >
      {/** top bar with logo and buttons */}
      <TopBar
        isLargeScreen={isLargeScreen}
        mobileMenuOpen={mobileMenuOpen}
        handleMobileMenu={handleMobileMenu}
        setPanelOpen={setPanelOpen}
        handleSearchMenu={handleSearchMenu}
        dict={dict}
        ctaTopBarButtons={ctaTopBarButtons}
        portalLoginCtaLink={portalLoginCtaLink}
      />

      {/** ESG EXTRA MENU ON MOBILE */}
      {!isLargeScreen && isESG && (
        <ESGMobileMenu dict={dict.navigation} pathname={pathname} isHidden={isHidden} isLargeScreen={isLargeScreen} />
      )}

      {/** mobile menu */}
      {mobileMenuOpen && (
        <MobileMenu lang={lang || "en"} contactNumber={contactNumber} dict={dict.navigation} navData={navData} />
      )}
      {/* <Transition
        show={mobileMenuOpen}
        enter="transition-opacity ease-out duration-200"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="transition-opacity ease-out duration-300"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <MobileMenu lang={lang || "en"} dict={dict.navigation} navData={navData} />
      </Transition> */}

      {/** search panel - mobile */}
      {searchMenuOpen && <SearchMobileMenu lang={lang} dict={dict.navigation} setSearchMenuOpen={setSearchMenuOpen} />}
      {/* <Transition
        show={searchMenuOpen}
        enter="transition-opacity ease-out duration-200"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="transition-opacity ease-out duration-300"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <SearchMobileMenu lang={lang} dict={dict.navigation} setSearchMenuOpen={setSearchMenuOpen} />
      </Transition> */}

      {/** main links */}
      <div className="h-0 overflow-y-visible">
        <MegaMenuLinks
          dict={dict}
          lang={lang}
          isHidden={isHidden}
          isVisible={isVisible}
          pathname={pathname}
          navData={navData}
          esgMenuItems={esgMenuItems}
          contactNumber={contactNumber}
        />
      </div>
    </div>
  );
};

export default React.memo(Navbar);
