"use client";

import { usePathname } from "next/navigation";
import { cn } from "@/lib/utils";

import { Home20 } from "@/assets/Icons";
import AppText from "../other/AppText";
import { Locale, i18n } from "@/i18n.config";
import CustomLink from "../other/CustomLink";
import { useBreadcrumbsLabel, useContentfulBreadcrumbs } from "@/store/clientStore";
import { ChevronRight } from "lucide-react";
import { useEffect, useState } from "react";

interface IBreadcrumbs {
  lang: Locale;
  dict: { [key: string]: string };
  isVisible: boolean;
}

const SKIP_LINK_FOR: { [key: string]: 1 } = {
  "/request-quote-buy": 1,
  "/resources": 1,
};

const Breadcrumbs = ({ lang, dict, isVisible = true }: IBreadcrumbs) => {
  const paths = usePathname();
  const [mounted, setMounted] = useState(false);
  const { branchName, unitNumber } = useBreadcrumbsLabel();
  const { contentfulBreadcrumbs } = useContentfulBreadcrumbs();
  let pathNames = paths.split("/").filter((path) => path);

  const [first, ...rest] = pathNames;
  const show = isVisible && paths !== "/en" && paths !== "/fr";
  // @ts-expect-error: variable "first" infers to string
  if (i18n.locales.includes(first)) {
    pathNames = rest ?? [];
  }

  useEffect(() => {
    setMounted(true);
  }, []);

  if (!mounted) {
    return null;
  }

  return (
    <div
      className={cn(
        "w-full bg-white transition-all transform duration-200 z-0 border-b-[1px] border-black-20 overflow-auto hideScrollbar",
        // isVisible ? "translate-y-0" : "-translate-y-full"
        show ? "translate-y-0" : "-translate-y-full"
      )}
    >
      <div
        className={`w-full flex mx-auto z-1 
      xl:max-w-screen-2xl lg:max-w-screen-xl sm:max-w-screen-md md:max-w-screen-lg
      px-4 lg:px-8 xl:px-[80px] 2xl:px-[104px] bg-white transition-all transform duration-200
    `}
        aria-label="Breadcrumb"
      >
        <ul className="inline-flex items-center pr-4 my-2 space-x-1">
          {/** HOME */}
          <li className="inline-flex items-center">
            <CustomLink href="/" aria-label="Home">
              <Home20 className="fill-slate-60 hover:fill-slate transition" />
            </CustomLink>
            <ChevronRight size={20} color="#666666" aria-label="chevron right icon" />
          </li>

          {/** THE REST OF THE PATH */}
          {!pathNames.length && (
            <li className="whitespace-nowrap flex items-center">
              <AppText type="BODY_SMALL_EMPHASIS" className="text-black">
                {dict.home}
              </AppText>
            </li>
          )}

          {contentfulBreadcrumbs &&
          contentfulBreadcrumbs?.pathname === paths &&
          contentfulBreadcrumbs?.breadcrumbs?.length
            ? contentfulBreadcrumbs.breadcrumbs.map((breadcrumb: { id: string; key: string; value: string }, index) => {
                {
                  /** CONTENTFUL BREADCRUMBS */
                }
                return (
                  <li key={breadcrumb.id} className="whitespace-nowrap flex items-center">
                    {breadcrumb.value ? (
                      <CustomLink href={breadcrumb.value}>
                        <AppText
                          type="BODY_SMALL_EMPHASIS"
                          className={`text-black-60 hover:text-black hover:decoration-slate
                          decoration-transparent transition`}
                        >
                          {breadcrumb.key}
                        </AppText>
                      </CustomLink>
                    ) : (
                      <AppText
                        type="BODY_SMALL_EMPHASIS"
                        className={`text-black-60 hover:text-black hover:decoration-slate
                    decoration-transparent transition`}
                      >
                        {breadcrumb.key}
                      </AppText>
                    )}

                    {contentfulBreadcrumbs.breadcrumbs.length !== index + 1 && (
                      <ChevronRight size={20} color="#666666" aria-label="chevron right icon" />
                    )}
                  </li>
                );
              })
            : pathNames.map((link, index) => {
                {
                  /** OLD BREADCRUMBS */
                }
                let href = `/${pathNames.slice(0, index + 1).join("/")}`;
                const text = link[0] + link.slice(1, link.length);
                const last = paths === `/${lang}${href}`;
                let isLink = true;

                if (href.startsWith("/locations") && href !== "/locations" && !last) {
                  return null;
                }

                if (!last && href === "/about-us") {
                  href = "/about-us/our-company";
                }

                if (!last && href === "/about-us/sustainability-impact") {
                  href = "/about-us/sustainability-impact/sustainability-home";
                }

                if (!last) {
                  if (SKIP_LINK_FOR[href]) {
                    isLink = false;
                  }
                }

                // if (!last) {
                //   if (href === "/about-us/sustainability-impact/esg-impact") {
                //     href = "/about-us/sustainability-impact/sustainability";
                //   }
                // }

                if (last) {
                  let label;

                  if (href.startsWith("/sales-showroom/") && pathNames.length === 2) {
                    if (unitNumber.toLowerCase() === text.toLowerCase()) {
                      label = unitNumber;
                    } else {
                      label = dict[text] || text.replace(/-/g, " ");

                      if (label.endsWith(" cc")) {
                        label = label.replace(" cc", " CC");
                      }
                    }
                  } else {
                    label = dict[text] || text.replace(/-/g, " ");

                    if (href.startsWith("/request-quote-buy/")) {
                      label = dict[text] || decodeURI(label).toUpperCase();
                    }

                    if (href.startsWith("/locations") && pathNames.length === 3) {
                      if (label.toLowerCase().startsWith(branchName.toLowerCase())) {
                        label = branchName;
                      }
                    }
                  }

                  return (
                    <li key={index} className="whitespace-nowrap flex items-center">
                      <AppText type="BODY_SMALL_EMPHASIS" className={cn("text-black", !dict[text] && "capitalize")}>
                        {label}
                      </AppText>
                    </li>
                  );
                }

                return (
                  <li key={index} className="whitespace-nowrap flex items-center">
                    {isLink ? (
                      <CustomLink href={href}>
                        <AppText
                          type="BODY_SMALL_EMPHASIS"
                          className={cn(
                            `text-black-60 hover:text-black hover:decoration-slate 
                    decoration-transparent transition`,
                            !dict[text] && "capitalize"
                          )}
                        >
                          {dict[text] || text.replace(/-/g, " ")}
                        </AppText>
                      </CustomLink>
                    ) : (
                      <AppText
                        type="BODY_SMALL_EMPHASIS"
                        className={cn(
                          `text-black-60 hover:text-black hover:decoration-slate 
                    decoration-transparent transition`,
                          !dict[text] && "capitalize"
                        )}
                      >
                        {dict[text] || text.replace(/-/g, " ")}
                      </AppText>
                    )}

                    {pathNames.length !== index + 1 && (
                      <ChevronRight size={20} color="#666666" aria-label="chevron right icon" />
                    )}
                  </li>
                );
              })}
        </ul>
      </div>
    </div>
  );
};

export default Breadcrumbs;
