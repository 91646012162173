import { i18n } from "@/i18n.config";
import Cookies from "js-cookie";
// import { AppRouterInstance } from "next/dist/shared/lib/app-router-context.shared-runtime";

const redirectedPathName = (locale: string, pathName: string) => {
  if (!pathName) return "/";

  const pathnameIsMissingLocale = i18n.locales.every(
    (locale) => !pathName.startsWith(`/${locale}/`) && pathName !== `/${locale}`
  );

  if (pathnameIsMissingLocale) {
    return `/${locale}${pathName}`;
  } else {
    const segments = pathName.split("/");
    segments[1] = locale;

    return segments.join("/");
  }
};

export const switchLanguage = async (locale: string, pathName: string /* router: string[] | AppRouterInstance */) => {
  if (typeof window === "undefined") return;

  Cookies.set("NEXT_APP_LANG", locale, { expires: 365 });

  const host = window.location.host;
  const newPath = redirectedPathName(locale, pathName);

  // router.push(newPath); // ! this breaks chat, need hard reload instead

  await new Promise((resolve) => setTimeout(resolve, 500));

  // const isDevelopment = process.env.NODE_ENV === "development"
  if (host.startsWith("localhost")) {
    window.location.href = `http://${window.location.host}${newPath}`;
  } else {
    window.location.href = `https://${window.location.host}${newPath}`;
  }
};
