"use client";

import { useEffect } from "react";
import { usePathname } from "next/navigation";

// this is a helper component to trigger call rail swap method every time the pathname changes
// otherwise script is triggered only once when loaded

const CallRailTrigger = () => {
  const pathname = usePathname();

  useEffect(() => {
    if (window.CallTrk) {
      window.CallTrk.swap();
    }
  }, [pathname]);

  return null;
};

export default CallRailTrigger;
