/* eslint-disable @typescript-eslint/no-explicit-any */
import React from "react";
import CustomLink from "../other/CustomLink";
import Image from "next/image";
import { AppIconButton } from "../buttons/AppIconButton";
import { ChevronRight } from "lucide-react";
import AppText from "../other/AppText";
import { cn } from "@/lib/utils";
import { BODY_SMALL_EMPHASIS } from "@/styles";

const NavPanelNoMenu = ({ data }: { data: any }) => {
  return (
    <div className="p-6 pt-8">
      <div>
        <div className="flex items-center justify-between">
          <div>
            <AppText type={"SUB_HEADING_SMALL"} className="text-royal-blue">
              {data.tabHeading}
            </AppText>

            <AppText type={"BODY_SMALL"} className={"text-black-80"}>
              {data.tabSubheading}
            </AppText>
          </div>

          {data.tabCtaLink && (
            <AppIconButton
              icon={<ChevronRight size={20} aria-label="chevron right icon" />}
              label={data.tabCtaLabel}
              intent={"ghost"}
              iconOnRightSide={true}
              link={data.tabCtaLink}
              className="py-1"
            />
          )}
        </div>
      </div>

      <div
        className={cn(
          "mt-8 grid grid-cols-12 gap-x-8 gap-y-8 xl:gap-x-10",
          data.cards.length > 4 ? "grid-rows-2" : "grid-rows-1"
        )}
      >
        {(data.cards || []).map((card: any) => {
          return (
            <CustomLink href={card.ctaLink} key={card.id} className="group flex flex-col col-span-3 overflow-hidden">
              <div className="overflow-hidden relative aspect-[16/9]">
                <Image
                  className="group-hover:scale-105 aspect-[16/9] object-cover w-full transition"
                  fill
                  placeholder="blur"
                  blurDataURL={card.placeholder}
                  sizes="100vw"
                  src={card.image}
                  alt={card.ctaLabel}
                  priority
                />
              </div>

              <div className="w-full">
                <AppIconButton
                  tabIndex={-1}
                  icon={<ChevronRight size={20} aria-label="chevron right icon" />}
                  label={card.ctaLabel}
                  intent={"ghost"}
                  iconOnRightSide={true}
                  className={cn(BODY_SMALL_EMPHASIS, "justify-between w-full text-left whitespace-normal")}
                />
              </div>

              {/* {kind === "About us" && <AppText type="BODY_SMALL">{card.subTitle}</AppText>} */}
            </CustomLink>
          );
        })}
      </div>
    </div>
  );
};

export default NavPanelNoMenu;
