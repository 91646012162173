"use client";

import { useEffect, useState } from "react";
import { ChevronDown, Globe } from "lucide-react";
import { usePathname } from "next/navigation";

import { Popover, PopoverContent, PopoverTrigger } from "@/components/ui/popover";
import { Locale, i18n } from "@/i18n.config";
import { useLocale } from "@/store/clientStore";
import { navTriggerStyle } from "../other/constants";
import { switchLanguage } from "@/lib/language";
import { cn } from "@/lib/utils";

interface ILocaleSwitcher {
  lang: Locale;
  dict: { [key: string]: string };
}

export default function LocaleSwitcher({ lang, dict }: ILocaleSwitcher) {
  const pathName = usePathname();
  // const router = useRouter();
  const { setLocale } = useLocale();
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (typeof window === "undefined") return;

    setLocale(lang);
  }, [lang, setLocale]);

  return (
    <li>
      <Popover onOpenChange={setOpen}>
        <div className="text-4 relative flex items-center h-8">
          <PopoverTrigger
            className={cn(navTriggerStyle, "gap-2 h-8 megaMenuLinks font-saira", open && "after:scale-x-100")}
          >
            <Globe size={14} className="stroke-[1.5px]" aria-label="globe icon" />

            {dict[lang]}

            <ChevronDown size={16} className={cn("transition", open && "rotate-180")} aria-label="chevron down icon" />
          </PopoverTrigger>
        </div>
        <PopoverContent className="w-full rounded-[0px] shadow-2xl border-[1px] border-slate-10 mt-2 border-t-none z-[300]">
          <ul className="flex flex-col gap-3">
            {i18n.locales.map((locale) => {
              return (
                <li key={locale} className="relative">
                  <button
                    id="button-locale-switcher"
                    onClick={() => switchLanguage(locale, pathName)}
                    // onClick={() => switchLanguage(locale, pathName, router )}
                    className={cn(navTriggerStyle, "w-[160px] justify-between megaMenuLinks font-saira")}
                  >
                    <span>{dict[`${locale}-long`]}</span>

                    <span> {dict[locale]}</span>
                  </button>
                </li>
              );
            })}
          </ul>
        </PopoverContent>
      </Popover>
    </li>
  );
}
