import { cn } from "@/lib/utils";

interface IMenuButtonProps {
  open: boolean;
  className?: string;
}

const MenuButton: React.FC<IMenuButtonProps> = ({ open, className }) => {
  return (
    <button aria-label="Menu" className={cn("flex items-center justify-center", className)}>
      {open ? (
        <svg
          aria-label="menu icon"
          xmlns="http://www.w3.org/2000/svg"
          width="32"
          height="32"
          viewBox="0 0 32 32"
          fill="none"
        >
          <mask maskUnits="userSpaceOnUse" x="0" y="0" width="32" height="32">
            <rect width="32" height="32" fill="#D9D9D9" />
          </mask>
          <g mask="url(#mask0_3694_15817)">
            <path
              d="M16 17.1795L9.0102 24.1692C8.84954 24.3299 8.6551 24.4124 8.4269 24.4166C8.1987 24.4209 7.99999 24.3384 7.83077 24.1692C7.66153 24 7.5769 23.8034 7.5769 23.5795C7.5769 23.3556 7.66153 23.159 7.83077 22.9898L14.8205 16L7.83077 9.01023C7.67008 8.84957 7.5876 8.65513 7.58334 8.42693C7.57905 8.19873 7.66153 8.00002 7.83077 7.8308C7.99999 7.66156 8.19656 7.57693 8.42047 7.57693C8.6444 7.57693 8.84098 7.66156 9.0102 7.8308L16 14.8205L22.9897 7.8308C23.1504 7.67011 23.3448 7.58763 23.573 7.58337C23.8012 7.57908 23.9999 7.66156 24.1692 7.8308C24.3384 8.00002 24.423 8.19659 24.423 8.4205C24.423 8.64443 24.3384 8.84101 24.1692 9.01023L17.1794 16L24.1692 22.9898C24.3299 23.1504 24.4123 23.3449 24.4166 23.5731C24.4209 23.8013 24.3384 24 24.1692 24.1692C23.9999 24.3384 23.8034 24.4231 23.5795 24.4231C23.3555 24.4231 23.159 24.3384 22.9897 24.1692L16 17.1795Z"
              fill="#051C2C"
            />
          </g>
        </svg>
      ) : (
        <svg
          aria-label="close menu icon"
          xmlns="http://www.w3.org/2000/svg"
          width="32"
          height="32"
          viewBox="0 0 32 32"
          fill="none"
        >
          <mask maskUnits="userSpaceOnUse" x="0" y="0" width="32" height="32">
            <rect width="32" height="32" fill="#D9D9D9" />
          </mask>
          <g mask="url(#mask0_3694_9127)">
            <path
              d="M5.17091 23.5128C4.9336 23.5128 4.73468 23.4321 4.57414 23.2707C4.41361 23.1093 4.33334 22.9093 4.33334 22.6707C4.33334 22.4321 4.41361 22.2336 4.57414 22.0752C4.73468 21.9168 4.9336 21.8376 5.17091 21.8376H26.829C27.0664 21.8376 27.2653 21.9183 27.4258 22.0797C27.5863 22.2411 27.6666 22.4411 27.6666 22.6797C27.6666 22.9183 27.5863 23.1168 27.4258 23.2752C27.2653 23.4336 27.0664 23.5128 26.829 23.5128H5.17091ZM5.17091 16.8376C4.9336 16.8376 4.73468 16.7569 4.57414 16.5955C4.41361 16.4341 4.33334 16.2341 4.33334 15.9955C4.33334 15.7569 4.41361 15.5584 4.57414 15.4C4.73468 15.2416 4.9336 15.1624 5.17091 15.1624H26.829C27.0664 15.1624 27.2653 15.2431 27.4258 15.4045C27.5863 15.5659 27.6666 15.7659 27.6666 16.0045C27.6666 16.2431 27.5863 16.4416 27.4258 16.6C27.2653 16.7584 27.0664 16.8376 26.829 16.8376H5.17091ZM5.17091 10.1624C4.9336 10.1624 4.73468 10.0817 4.57414 9.92028C4.41361 9.75888 4.33334 9.55889 4.33334 9.32031C4.33334 9.08171 4.41361 8.88321 4.57414 8.72481C4.73468 8.56641 4.9336 8.48721 5.17091 8.48721H26.829C27.0664 8.48721 27.2653 8.56791 27.4258 8.72931C27.5863 8.89071 27.6666 9.09071 27.6666 9.32931C27.6666 9.56789 27.5863 9.76638 27.4258 9.92478C27.2653 10.0832 27.0664 10.1624 26.829 10.1624H5.17091Z"
              fill="#051C2C"
            />
          </g>
        </svg>
      )}
    </button>
  );
};

export default MenuButton;
